import React from "react"

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faChevronRight,
    faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons'

import "../styles/site.scss"
import {Link} from "gatsby";
// import "../styles/debug.scss"
// import "../styles/helpers.scss"
// import "../styles/grid.scss"


const IndexPage = () => {
    return (
        <>
            <section className="hero is-medium">
                <div className="hero-body">
                    <div className="container">
                        <h1 className="title is-1">Gestus Metal</h1>
                        <h2 className="subtitle">Bravarske usluge</h2>
                        <a href="#" className="button is-white is-medium is-inverted">O nama&ensp;<FontAwesomeIcon
                            icon={faChevronRight}/></a>
                    </div>
                </div>
            </section>

            <section id="parallax-1" className="hero is-large">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-6 is-offset-6">
                                <h1 className="title is-1">Usluge savijanja</h1>
                                <hr className="content-divider"/>
                                <h2 className="subtitle">Savijanje svih vrsta profila od čelika, aluiminija ili inoxa od
                                    0,5mm do 12mm debljine.
                                    <br/>
                                    Dužina savijanja do 6000mm.
                                </h2>
                                <Link to="/profili">
                                    <p className="button is-white is-inverted">Saznaj više&ensp;
                                        <FontAwesomeIcon
                                            icon={faChevronRight}/></p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="parallax-2" className="hero is-large">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-6">
                                <h1 className="title is-1">Proizvodi</h1>
                                <hr className="content-divider"/>
                                <h2 className="subtitle">Metalne ograde za koje nije potreban majstor!
                                    <br/>
                                    Jednostavna ugradnja.
                                    <br/>
                                    Mogučnost prilagodbe dimenzija.
                                    <br/>
                                    Neiscrpna mogučnost kombiniranja materijala i boja...
                                </h2>
                                <h2 className="title is-4 is-italic"><br/>Zaštićen industrijski dizajn.</h2>
                                <br/>
                                <a href="#" className="button is-white is-inverted">Saznaj više&ensp;<FontAwesomeIcon
                                    icon={faChevronRight}/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="parallax-3" className="hero is-large">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns">
                            <div className="column is-6 is-offset-6">
                                <h1 className="title is-1">Ostale usluge</h1>
                                <hr className="content-divider"/>
                                <h2 className="subtitle">Savijanje nestandardnih profila, izrada i reparacija gospodarskih
                                    prikolica, izrada raznoraznih metalnih konstrukcija...</h2>
                                <a href="#" className="button is-white is-inverted">Saznaj više&ensp;<FontAwesomeIcon
                                    icon={faChevronRight}/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cta va">
                <div className="container">
                    <div className="columns">
                        <div className="column is-6">
                            <h1 className="title is-1 ">Kontakt</h1>
                            <hr className="content-divider"/>
                            <h2 className="subtitle">
                                Eugena Kumičića 2,
                                <br/>
                                31540 Donji Miholjac
                                <br/>
                                <br/>
                                +385 95 9044 611
                                <br/>
                                gestus.metal@gmail.com
                                <br/>
                                <br/>
                                Pogon se nalazi unutar industrijske zone u Donjem Miholjcu.
                                <br/>
                                Lokacija je označena na google&copy; mapi.
                                <br/>
                                <br/>
                                <a href="https://goo.gl/maps/yQu5VxMtdJ9E8NpT7" className="button is-white is-inverted"
                                   target="_blank">Prikaži lokaciju na mapi&ensp;<FontAwesomeIcon icon={faMapMarkerAlt}/></a>
                            </h2>
                        </div>

                        <div className="column is-6">
                            <figure className="image is-3by2">
                                <img src="images/mapa.jpg"/>
                            </figure>
                        </div>
                    </div>
                </div>

            </section>

            <footer className="footer">
                <div className="content has-text-centered">
                    <p>
                        <strong>Gestus Metal</strong> &copy; 2021
                    </p>
                </div>
            </footer>
        </>
    )
}

export default IndexPage
